package uz.ferro.shop.pages.sandbox

import mui.material.Stack
import mui.material.StackDirection
import mui.system.Box
import mui.system.responsive
import mui.system.sx
import react.FC
import react.Props
import react.useContext
import uz.ferro.shop.AppContext
import uz.ferro.shop.Colors
import web.cssom.Auto
import web.cssom.Color
import web.cssom.LineStyle
import web.cssom.TextAlign
import web.cssom.VerticalAlign
import web.cssom.number
import web.cssom.pct
import web.cssom.px

val Sandbox = FC<Props> {
    val appContext = useContext(AppContext)
    val isMobile = appContext.isMd.not()

    Box {
        sx {
            width = 100.pct
            height = 600.px
            padding = 30.px
        }

        Stack {
            direction = responsive(StackDirection.column)
            sx {
                borderColor = Colors.green
                borderStyle = LineStyle.solid
                borderWidth = 1.px
                height = Auto.auto
                gap = 16.px
            }

            Stack {
                direction = responsive(StackDirection.row)
                sx {
                    borderColor = Colors.accentColor
                    borderStyle = LineStyle.solid
                    borderWidth = 1.px
                    height = Auto.auto
                    gap = 8.px
                }

                Box {
                    sx {
                        verticalAlign = VerticalAlign.middle
                        textAlign = TextAlign.center
                        background = Color("#2e86c1")
                        aspectRatio = number(200.0 / 100.0)
                        flexGrow = number(200.0 / 100.0)
                        width = 0.pct
                        height = Auto.auto
                    }
                    +"200 x 100"
                }

                Box {
                    sx {
                        verticalAlign = VerticalAlign.middle
                        textAlign = TextAlign.center
                        background = Color("#f1948a")
                        flexGrow = number(300.0 / 200.0)
                        aspectRatio = number(300.0 / 200.0)
                        width = 0.pct
                        height = Auto.auto
                    }
                    +"300 x 200"
                }

                Box {
                    sx {
                        verticalAlign = VerticalAlign.middle
                        textAlign = TextAlign.center
                        background = Color("#82e0aa")
                        flexGrow = number(200.0 / 200.0)
                        aspectRatio = number(200.0 / 200.0)
                        width = 0.pct
                        height = Auto.auto
                    }
                    +"200 x 200"
                }
            }

            Stack {
                direction = responsive(StackDirection.row)
                sx {
                    borderColor = Colors.accentColor
                    borderStyle = LineStyle.solid
                    borderWidth = 1.px
                    height = Auto.auto
                    gap = 8.px
                }

                Box {
                    sx {
                        verticalAlign = VerticalAlign.middle
                        textAlign = TextAlign.center
                        background = Color("#e59866")
                        aspectRatio = number(200.0 / 100.0)
                        flexGrow = number(200.0 / 100.0)
                        width = 0.pct
                        height = Auto.auto
                    }
                    +"200 x 100"
                }

                Box {
                    sx {
                        verticalAlign = VerticalAlign.middle
                        textAlign = TextAlign.center
                        background = Color("#58d68d")
                        flexGrow = number(300.0 / 200.0)
                        aspectRatio = number(300.0 / 200.0)
                        width = 0.pct
                        height = Auto.auto
                    }
                    +"300 x 200"
                }
            }
        }


    }
}