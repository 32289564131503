package uz.ferro.shop.pages

import emotion.react.css
import kotlinx.browser.window
import kotlinx.coroutines.MainScope
import kotlinx.coroutines.launch
import mui.base.ClickAwayListener
import mui.icons.material.Close
import mui.icons.material.Login
import mui.icons.material.Logout
import mui.material.Box
import mui.material.Button
import mui.material.ButtonVariant
import mui.material.Dialog
import mui.material.DialogActions
import mui.material.DialogContent
import mui.material.DialogTitle
import mui.material.Divider
import mui.material.Drawer
import mui.material.DrawerAnchor
import mui.material.IconButton
import mui.material.Link
import mui.material.LinkUnderline
import mui.material.Menu
import mui.material.MenuItem
import mui.material.Orientation
import mui.material.Paper
import mui.material.Popper
import mui.material.Size
import mui.material.Stack
import mui.material.StackDirection
import mui.material.SvgIconSize
import mui.system.Breakpoint
import mui.system.responsive
import mui.system.sx
import popper.core.Placement
import react.FC
import react.Props
import react.create
import react.dom.html.ReactHTML.img
import react.useContext
import react.useEffect
import react.useEffectOnce
import react.useState
import uz.ferro.shop.AppContext
import uz.ferro.shop.Colors
import uz.ferro.shop.Const
import uz.ferro.shop.LocaleContext
import uz.ferro.shop.NavigationContext
import uz.ferro.shop.icon.CallIcon
import uz.ferro.shop.icon.CartIcon
import uz.ferro.shop.icon.ChevronBottomIcon
import uz.ferro.shop.icon.FacebookIcon32
import uz.ferro.shop.icon.FavoriteIcon
import uz.ferro.shop.icon.HamburgerIcon20
import uz.ferro.shop.icon.HeaderCallIcon
import uz.ferro.shop.icon.HeaderClockIcon
import uz.ferro.shop.icon.HeaderTelegramIcon
import uz.ferro.shop.icon.InstagramIcon32
import uz.ferro.shop.icon.LanguageIcon
import uz.ferro.shop.icon.LocationIcon
import uz.ferro.shop.icon.MarketIcon
import uz.ferro.shop.icon.MarketIcon20
import uz.ferro.shop.icon.ProfileIcon
import uz.ferro.shop.icon.RouteIcon
import uz.ferro.shop.icon.SvgIconViewProps
import uz.ferro.shop.icon.TelegramIcon32
import uz.ferro.shop.locale.Language
import uz.ferro.shop.manager.AuthManager
import uz.ferro.shop.model.SalePoint
import uz.ferro.shop.navigator.NavigationPage
import uz.ferro.shop.pages.admin.salepoint.SalePointManager
import uz.ferro.shop.ui.AppText
import uz.ferro.shop.ui.AppTextBold
import uz.ferro.shop.ui.AppTextBoldLarge
import uz.ferro.shop.ui.AppTextSemiBold
import uz.ferro.shop.ui.AppTextSemiBoldSmall
import uz.ferro.shop.ui.search.SearchView
import uz.ferro.shop.util.orZero
import web.cssom.AlignItems
import web.cssom.Auto
import web.cssom.Cursor
import web.cssom.Display
import web.cssom.FlexDirection
import web.cssom.JustifyContent
import web.cssom.None
import web.cssom.Overflow
import web.cssom.Position
import web.cssom.WhiteSpace
import web.cssom.integer
import web.cssom.number
import web.cssom.pct
import web.cssom.px
import web.cssom.vh
import web.dom.Element
import web.window.WindowTarget

private const val TOP_STRIPE_HEIGHT = 30
private const val FIRST_ROW_HEIGHT = 60
private const val FIRST_ROW_MARGIN = 20
private const val SEARCH_BAR_HEIGHT = 64
private const val STICKY_OFFSET = TOP_STRIPE_HEIGHT + FIRST_ROW_HEIGHT + 2 * FIRST_ROW_MARGIN
private const val STICKY_OFFSET_MOBILE = 1

external interface HeaderProps : Props {
    var cartItemsCount: Int?
}

val Header = FC<HeaderProps> { props ->
    val locale = useContext(LocaleContext)
    val navigator = useContext(NavigationContext)

    val appContext = useContext(AppContext)
    val isMobile = appContext.isMd.not()
    var isStickyHeader by useState(false)

    useEffectOnce {
        val stickyOffset = if (isMobile) STICKY_OFFSET_MOBILE else STICKY_OFFSET
        window.addEventListener("scroll", { _ ->
            isStickyHeader = window.pageYOffset.toInt() > stickyOffset
        })
    }

    val dotMargin = when {
        appContext.isMd -> 24.px
        else -> 8.px
    }

    Box {

        if (isMobile && isStickyHeader) {
            sx {
                position = Position.fixed
                width = 100.pct
                top = 0.px
                zIndex = integer(1000) // always must be less than navigation drawer z-index (1200)
                background = Colors.backgroundColor
            }
        }

        Stack {
            direction = responsive(StackDirection.row)
            sx {
                height = TOP_STRIPE_HEIGHT.px
                width = 100.pct
                backgroundColor = Colors.accentColor
                alignItems = AlignItems.center
                justifyContent = JustifyContent.center
                fontSize = 13.px
                color = Colors.accentContrastColor
                fontWeight = integer(700)
            }

            Box {
                sx {
                    cursor = Cursor.pointer
                }

                onClick = {
                    navigator.openCategory(Const.CATEGORY_FASTENERS_ID)
                }

                if (appContext.isMd) {
                    +locale.headerFasteners
                } else {
                    +locale.headerFastenersShort
                }
            }

            Box {
                sx {
                    marginLeft = dotMargin
                    marginRight = dotMargin
                }
                +"●"
            }

            Box {
                sx {
                    cursor = Cursor.pointer
                }

                onClick = {
                    navigator.openCategory(Const.CATEGORY_CONSUMABLES_ID)
                }

                if (appContext.isMd) {
                    +locale.headerConsumables
                } else {
                    +locale.headerConsumablesShort
                }
            }

            Box {
                sx {
                    marginLeft = dotMargin
                    marginRight = dotMargin
                }
                +"●"
            }

            Box {
                sx {
                    cursor = Cursor.pointer
                }

                onClick = {
                    navigator.openCategory(Const.CATEGORY_INSTRUMENTS_ID)
                }

                if (appContext.isMd) {
                    +locale.headerInstruments
                } else {
                    +locale.headerInstrumentsShort
                }
            }
        }

        if (isMobile.not()) {
            HeaderTopRow()

            Divider {
                sx {
                    borderBottomWidth = 1.5f.px
                    borderColor = Colors.headerDivider
                }
            }

            Box {
                if (isStickyHeader) {
                    sx {
                        backgroundColor = Colors.backgroundColor
                        position = Position.fixed
                        width = 100.pct
                        top = 0.px
                        zIndex = integer(9999)
                    }
                }

                HeaderSearchRow {
                    cartItemsCount = props.cartItemsCount
                }
                Divider {
                    sx {
                        borderBottomWidth = 1.5f.px
                        borderColor = Colors.headerDivider
                    }
                }
            }

            // workaround for empty space after sticky searchbar
            if (isStickyHeader) {
                Box {
                    sx {
                        height = SEARCH_BAR_HEIGHT.px
                    }
                }
            }
        } else {
            HeaderTopMobile {
                cartItemsCount = props.cartItemsCount
            }
            Divider {
                sx {
                    borderBottomWidth = 1.px
                    borderColor = Colors.headerDivider
                }
            }
        }
    }

    // Workaround space in sticky mode
    if (isMobile && isStickyHeader) {
        Box {
            sx {
                width = 100.pct
                height = 138.px // Header full height
            }
        }
    }
}

external interface TopContactsMenuProps : Props {
    var isHorizontal: Boolean
}

private val TopContactsMenu = FC<TopContactsMenuProps> { props ->
    val appContext = useContext(AppContext)
    val locale = useContext(LocaleContext)

    Stack {
        if (props.isHorizontal) {
            direction = responsive(StackDirection.row)
        } else {
            direction = responsive(StackDirection.column)
            spacing = responsive(24.px)
        }

        /*

        HeaderRowItem(
            icon = HeaderMailIcon,
            title = locale.makeOrder,
            content = Const.INFO_EMAIL,
            linkify = LinkType.EMAIL
        ).invoke()

        if (props.isHorizontal) {
            HeaderDivider()
        }

        HeaderRowItem(
            icon = HeaderCallIcon,
            title = locale.makeOrder,
            content = Const.ORDER_PHONE,
            linkify = LinkType.PHONE
        ).invoke()

        if (props.isHorizontal) {
            HeaderDivider()
        }
        */

        HeaderRowItem(
            icon = HeaderCallIcon,
            title = locale.deliveryByTashkent,
            content = Const.TASHKENT_DELIVERY_PHONE,
            linkify = LinkType.PHONE
        ).invoke()

        if (props.isHorizontal) {
            HeaderDivider()
        }

        HeaderRowItem(
            icon = HeaderTelegramIcon,
            title = locale.makeOrder,
            content = Const.TELEGRAM_ORDER_TITLE,
            linkify = LinkType.WEB,
            link = Const.TELEGRAM_ORDER_LINK
        ).invoke()

        if (props.isHorizontal) {
            HeaderDivider()
        }

        HeaderRowItem(
            icon = HeaderClockIcon,
            title = "",
            content = Const.WORKING_TIME.replace("{{days}}", locale.workingDays)
        ).invoke()
    }
}


private val HeaderTopMobile = FC<HeaderProps> { props ->
    val navigator = useContext(NavigationContext)
    val locale = useContext(LocaleContext)
    var isAuthorized by useState(AuthManager.isAuthorized())
    var isDrawerOpened by useState(false)

    Stack {
        direction = responsive(StackDirection.column)

        sx {
            paddingLeft = 16.px
            paddingRight = 16.px
            paddingTop = 6.px
            paddingBottom = 6.px
        }

        Stack {
            direction = responsive(StackDirection.row)
            sx {
                alignItems = AlignItems.center
                paddingTop = 6.px
                paddingBottom = 6.px
            }

            Box {
                sx {
                    display = Display.inlineBlock
                    width = 20.px
                    height = 20.px
                    cursor = Cursor.pointer
                }
                HamburgerIcon20()
                onClick = {
                    isDrawerOpened = true
                }
            }

            img {
                src = "./logo_ferro.svg"
                css {
                    height = 14.px
                    cursor = Cursor.pointer
                    marginLeft = 12.px
                }
                onClick = {
                    navigator.openMain()
                }
            }

            /*
            Stack {
                direction = responsive(StackDirection.row)
                sx {
                    marginLeft = 6.px
                    alignItems = AlignItems.center
                }
                CallIcon16()

                Link {
                    sx {
                        marginLeft = 2.px
                        fontSize = 13.px
                    }
                    underline = LinkUnderline.hover
                    href = "tel: ${Const.ORDER_PHONE}"
                    AppTextSemiBoldSmall {
                        text = Const.ORDER_PHONE
                    }
                }
            }
            */

            Box {
                sx {
                    flexGrow = number(1.0)
                }
            }

            Box {
                sx {
                    display = Display.inlineBlock
                    width = 20.px
                    height = 20.px
                    cursor = Cursor.pointer
                }
                FavoriteIcon()
                onClick = {
                    navigator.openFavorites()
                }
            }

            Box {
                sx {
                    display = Display.inlineBlock
                    position = Position.relative
                    width = 20.px
                    height = 20.px
                    cursor = Cursor.pointer
                    marginLeft = 16.px
                }
                CartIcon()
                onClick = {
                    navigator.openCart()
                }

                if (props.cartItemsCount.orZero() > 0) {
                    Box {
                        sx {
                            display = Display.flex
                            flexDirection = FlexDirection.row
                            alignItems = AlignItems.center
                            justifyContent = JustifyContent.center
                            width = 16.px
                            height = 16.px
                            borderRadius = 8.px
                            backgroundColor = Colors.accentColor
                            color = Colors.accentContrastColor
                            position = Position.absolute
                            top = (-8).px
                            right = (-8).px
                            fontWeight = integer(500)
                            fontSize = 10.px
                        }
                        +props.cartItemsCount.toString()
                    }
                }
            }
        }

        Stack {
            direction = responsive(StackDirection.row)
            sx {
                alignItems = AlignItems.center
                paddingTop = 6.px
                paddingBottom = 6.px
            }

            Box {
                sx {
                    flexGrow = number(1.0)
                }

                SearchView()
            }

            Divider {
                sx {
                    borderBottomWidth = 1.px
                    borderColor = Colors.headerDivider
                }
            }
        }
    }

    Drawer {
        anchor = DrawerAnchor.left
        open = isDrawerOpened
        disableScrollLock = true
        onClose = { _, _ ->
            isDrawerOpened = false
        }

        Stack {
            direction = responsive(StackDirection.column)
            sx {
                minWidth = 300.px
            }

            Stack {
                direction = responsive(StackDirection.row)
                sx {
                    width = 100.pct
                    height = 56.px
                    paddingLeft = 16.px
                    alignItems = AlignItems.center
                    justifyContent = JustifyContent.spaceBetween
                    backgroundColor = Colors.accentColor
                }

                AppTextBoldLarge {
                    text = locale.menu
                    color = Colors.accentContrastColor
                }

                IconButton {
                    size = Size.small
                    Close {
                        htmlColor = Colors.accentContrastColor.toString()
                    }
                    onClick = {
                        isDrawerOpened = false
                    }
                }
            }

            Stack {
                direction = responsive(StackDirection.column)
                sx {
                    padding = 16.px
                }

                Stack {
                    direction = responsive(StackDirection.row)
                    sx {
                        minHeight = 56.px
                        alignItems = AlignItems.center
                    }
                    LanguageMenuView()
                }

                TopContactsMenu {
                    isHorizontal = false
                }

                if (AuthManager.isB2BUser()) {
                    Stack {
                        direction = responsive(StackDirection.row)
                        spacing = responsive(12.px)
                        sx {
                            marginTop = 24.px
                            alignItems = AlignItems.center
                        }

                        onClick = {
                            isDrawerOpened = false
                            navigator.openOrders()
                        }

                        ProfileIcon {
                            size = 36
                        }

                        AppTextBold {
                            text = locale.orders
                        }
                    }
                }

                Stack {
                    direction = responsive(StackDirection.row)
                    spacing = responsive(12.px)
                    sx {
                        justifyContent = JustifyContent.center
                        marginTop = 24.px
                    }
                    TelegramIcon32 {
                        clickAction = {
                            navigator.openUrl(Const.TELEGRAM)
                        }
                    }

                    InstagramIcon32 {
                        clickAction = {
                            navigator.openUrl(Const.INSTAGRAM)
                        }
                    }

                    FacebookIcon32 {
                        clickAction = {
                            navigator.openUrl(Const.FACEBOOK)
                        }
                    }
                }

                Box {
                    sx {
                        height = 48.px
                    }
                }

                LoginMenu()
            }
        }
    }
}

private val HeaderTopRow = FC<Props> {
    val navigator = useContext(NavigationContext)

    Stack {
        direction = responsive(StackDirection.row)
        sx {
            width = 100.pct
            height = FIRST_ROW_HEIGHT.px
            marginTop = FIRST_ROW_MARGIN.px
            marginBottom = FIRST_ROW_MARGIN.px
            maxWidth = MAX_PAGE_AREA
            marginLeft = Auto.auto
            marginRight = Auto.auto
            alignItems = AlignItems.center
            paddingLeft = 16.px
            paddingRight = 16.px
        }

        img {
            src = "./logo_ferro.svg"
            css {
                height = 38.px
                width = 226.px
                cursor = Cursor.pointer
            }
            onClick = {
                navigator.openMain()
            }
        }

        Box {
            sx {
                width = 174.px
            }
        }

        TopContactsMenu {
            isHorizontal = true
        }

        Box {
            sx {
                flexGrow = number(1.0)
                flexShrink = number(1.0)
            }
        }

        Box {
            sx {
                width = 24.px
            }
        }

        LanguageMenuView()

        if (AuthManager.isB2BUser()) {
            Stack {
                direction = responsive(StackDirection.row)
                sx {
                    marginLeft = 24.px
                    justifyContent = JustifyContent.center
                }

                ProfileIcon {
                    size = 36
                    clickAction = {
                        navigator.openOrders()
                    }
                }
            }
        }
    }
}

private val LanguageMenuView = FC<Props> {
    val locale = useContext(LocaleContext)
    var isLangPopupOpen by useState(false)
    var langPopupAnchor: Element? by useState()
    val appContext = useContext(AppContext)

    Stack {
        direction = responsive(StackDirection.row)
        sx {
            cursor = Cursor.pointer
        }

        LanguageIcon()

        Box {
            sx {
                fontSize = 16.px
                color = Colors.textPrimary
                fontWeight = integer(600)
                marginLeft = 6.px
                marginRight = 2.px
            }

            +locale.currentLanguageShort
        }

        ChevronBottomIcon()

        onClick = {
            langPopupAnchor = it.currentTarget
            isLangPopupOpen = !isLangPopupOpen
        }
    }

    Menu {
        sx {
            minWidth = 100.px
        }
        anchorEl = {
            langPopupAnchor ?: it
        }

        fun closeMenu() {
            isLangPopupOpen = false
        }

        open = isLangPopupOpen
        onClose = {
            closeMenu()
        }

        MenuItem {
            +locale.languageUzbLat
            onClick = {
                appContext.setLanguage(Language.UZ)
                closeMenu()
            }
        }

        MenuItem {
            +locale.languageUzbCyr
            onClick = {
                appContext.setLanguage(Language.UZ_CYR)
                closeMenu()
            }
        }

        MenuItem {
            +locale.languageRus
            onClick = {
                appContext.setLanguage(Language.RU)
                closeMenu()
            }
        }

        MenuItem {
            +locale.languageEng
            onClick = {
                appContext.setLanguage(Language.EN)
                closeMenu()
            }
        }
    }
}

private val HeaderDivider = FC<Props> {
    Divider {
        orientation = Orientation.vertical
        flexItem = true
        sx {
            borderColor = Colors.accentColor
            marginLeft = 18.px
            marginRight = 18.px
        }
    }
}

private fun HeaderRowItem(
    icon: FC<SvgIconViewProps>,
    title: String,
    content: String,
    linkify: LinkType? = null,
    link: String? = null
) = FC<Props> {
    val appContext = useContext(AppContext)

    val iconSize = if (appContext.isMd) 42 else 36
    val titleSize = if (appContext.isMd) 13 else 11
    val contextSize = if (appContext.isMd) 20 else 16

    Stack {
        direction = responsive(StackDirection.row)
        spacing = responsive(12.px)
        sx {
            alignItems = AlignItems.center
        }

        icon {
            size = iconSize
        }

        Stack {
            direction = responsive(StackDirection.column)
            spacing = responsive(2.px)

            if (title.isNotBlank()) {
                AppTextSemiBoldSmall {
                    size = titleSize
                    text = title
                }
            }

            Box {
                sx {
                    fontWeight = integer(700)
                    fontSize = contextSize.px
                    color = Colors.textPrimary
                    whiteSpace = WhiteSpace.preLine
                }

                when (linkify) {
                    LinkType.EMAIL -> {
                        Link {
                            sx {
                                fontWeight = integer(700)
                                fontSize = contextSize.px
                                color = Colors.textPrimary
                            }
                            underline = LinkUnderline.hover
                            href = "mailto: $content"
                            target = WindowTarget._blank
                            +content
                        }
                    }

                    LinkType.PHONE -> {
                        Link {
                            sx {
                                fontWeight = integer(700)
                                fontSize = contextSize.px
                                color = Colors.textPrimary
                            }
                            underline = LinkUnderline.hover
                            href = "tel: $content"
                            target = WindowTarget._blank
                            +content
                        }
                    }

                    LinkType.WEB -> {
                        Link {
                            sx {
                                fontWeight = integer(700)
                                fontSize = contextSize.px
                                color = Colors.textPrimary
                            }
                            underline = LinkUnderline.hover
                            href = link.orEmpty()
                            target = WindowTarget._blank
                            +content
                        }
                    }

                    else -> {
                        +content
                    }
                }
            }
        }
    }
}

private val HeaderSearchRow = FC<HeaderProps> { props ->
    val locale = useContext(LocaleContext)
    val navigator = useContext(NavigationContext)
    var dealersButtonTarget by useState<Element>()
    var isDealersPopupOpen by useState(false)

    Stack {
        direction = responsive(StackDirection.row)
        sx {
            width = 100.pct
            height = SEARCH_BAR_HEIGHT.px
            maxWidth = MAX_PAGE_AREA
            marginLeft = Auto.auto
            marginRight = Auto.auto
            alignItems = AlignItems.center
            paddingLeft = 16.px
            paddingRight = 16.px
        }

        Box {
            sx {
                flexGrow = number(2.0)
            }

            SearchView()
        }

        Box {
            sx {
                flexGrow = number(1.0)
            }
        }

        Button {
            startIcon = MarketIcon20.create()
            size = Size.small
            variant = ButtonVariant.contained
            sx {
                textTransform = None.none
                marginLeft = 24.px
            }
            onClick = { event ->
//                dealersButtonTarget = event.currentTarget
//                isDealersPopupOpen = !isDealersPopupOpen
                navigator.openPage(NavigationPage.STORES)
                event.preventDefault()
                event.stopPropagation()
            }
            +locale.dealers
        }

        DealersPopup {
            targetElement = dealersButtonTarget
            isOpen = isDealersPopupOpen
            close = {
                isDealersPopupOpen = false
            }
        }

        Stack {
            direction = responsive(StackDirection.row)
            sx {
                marginLeft = 24.px
                cursor = Cursor.pointer
            }

            FavoriteIcon()

            Box {
                sx {
                    fontSize = 16.px
                    color = Colors.textPrimary
                    fontWeight = integer(600)
                    marginLeft = 6.px
                    marginRight = 2.px
                }
                +locale.favorites
            }

            onClick = {
                navigator.openFavorites()
            }
        }

        Stack {
            direction = responsive(StackDirection.row)
            sx {
                marginLeft = 24.px
                cursor = Cursor.pointer
            }



            Box {
                sx {
                    position = Position.relative
                    width = 20.px
                    height = 20.px
                }
                CartIcon()

                if (props.cartItemsCount.orZero() > 0) {
                    Box {
                        sx {
                            display = Display.flex
                            flexDirection = FlexDirection.row
                            alignItems = AlignItems.center
                            justifyContent = JustifyContent.center
                            width = 16.px
                            height = 16.px
                            borderRadius = 8.px
                            backgroundColor = Colors.accentColor
                            color = Colors.accentContrastColor
                            position = Position.absolute
                            top = (-8).px
                            right = (-8).px
                            fontWeight = integer(500)
                            fontSize = 10.px
                        }
                        +props.cartItemsCount.toString()
                    }
                }
            }

            Box {
                sx {
                    fontSize = 16.px
                    color = Colors.textPrimary
                    fontWeight = integer(600)
                    marginLeft = 6.px
                    marginRight = 2.px
                }
                +locale.cart
            }

            onClick = {
                navigator.openCart()
            }
        }

        Box {
            sx {
                marginLeft = 24.px
            }

            LoginMenu()
        }
    }
}

private val LoginMenu = FC<Props> {
    val locale = useContext(LocaleContext)
    val navigator = useContext(NavigationContext)
    var isAuthorized by useState(AuthManager.isAuthorized())
    var showLogoutDialog by useState(false)

    fun closeLogoutDialog() {
        showLogoutDialog = false
    }

    Stack {
        direction = responsive(StackDirection.row)
        sx {
            cursor = Cursor.pointer
        }

        if (isAuthorized) {
            Logout {
                sx {
                    fontSize = 20.px
                }
                fontSize = SvgIconSize.inherit
                htmlColor = Colors.textPrimary.toString()
            }
            AppTextSemiBold {
                sx {
                    marginLeft = 6.px
                    marginRight = 2.px
                }
                text = locale.signOut
            }

            onClick = {
                showLogoutDialog = true

//                AuthManager.logout()
//                isAuthorized = false
            }
        } else {
            Login {
                sx {
                    fontSize = 20.px
                }
                fontSize = SvgIconSize.inherit
                htmlColor = Colors.textPrimary.toString()
            }
            AppTextSemiBold {
                sx {
                    marginLeft = 6.px
                    marginRight = 2.px
                }
                text = locale.signIn
            }

            onClick = {
                navigator.openLogin()
            }
        }
    }

    Dialog {
        open = showLogoutDialog
        onClose = { _, _ -> closeLogoutDialog() }
        DialogTitle {
            +locale.signOut
        }
        DialogContent {
            Box {
                sx {
                    padding = 16.px
                }

                AppText {
                    text = locale.exitConfirm
                }
            }

            DialogActions {
                Button {
                    onClick = {
                        closeLogoutDialog()
                    }
                    +locale.cancel
                }

                Button {
                    onClick = {
                        AuthManager.logout()
                        isAuthorized = false
                        closeLogoutDialog()
                    }
                    +locale.signOut
                }
            }
        }
    }
}

external interface DealersPopupProps : Props {
    var targetElement: Element?
    var isOpen: Boolean
    var close: () -> Unit
}

val DealersPopup = FC<DealersPopupProps> { props ->
    var salePoints by useState(listOf<SalePoint>())
    val locale = useContext(LocaleContext)
    val navigator = useContext(NavigationContext)
    val appContext = useContext(AppContext)

    useEffect(props.isOpen) {
        if (props.isOpen && salePoints.isEmpty()) {
            MainScope().launch {
                try {
                    salePoints = SalePointManager.getSalePoints()
                } catch (e: Exception) {
                    // no-op
                }
            }
        }
    }

    ClickAwayListener {
        onClickAway = {
            if (props.isOpen) {
                props.close()
            }
            it.preventDefault()
            it.stopPropagation()
        }

        val isMobile = appContext.isMd.not()

        Popper {
            sx {
                width = responsive(
                    Breakpoint.xs to 100.pct,
                    Breakpoint.md to 340.px
                )


                maxHeight = 90.vh
                overflowY = Overflow.scroll

                if (isMobile) {
                    paddingLeft = 16.px
                    paddingRight = 16.px
                }

                minHeight = 100.px
                zIndex = integer(9999)
            }
            anchorEl = props.targetElement
            open = props.isOpen
            placement = Placement.bottom
            Paper {
                sx {
                    padding = 12.px
                    width = 100.pct
                }

                Stack {
                    direction = responsive(StackDirection.column)
                    spacing = responsive(16.px)

                    salePoints.forEach { salePoint ->
                        Stack {
                            direction = responsive(StackDirection.column)
                            spacing = responsive(12.px)
                            Stack {
                                direction = responsive(StackDirection.row)
                                spacing = responsive(8.px)
                                MarketIcon()
                                AppTextBold {
                                    text = salePoint.getLocalizedName()
                                }
                            }

                            Stack {
                                direction = responsive(StackDirection.row)
                                spacing = responsive(8.px)
                                LocationIcon()
                                AppTextSemiBoldSmall {
                                    text = salePoint.getLocalizedAddress()
                                }
                            }

                            Stack {
                                direction = responsive(StackDirection.row)
                                spacing = responsive(8.px)
                                CallIcon()
                                Stack {
                                    direction = responsive(StackDirection.column)
                                    AppTextSemiBoldSmall {
                                        text = salePoint.phone
                                    }
                                    if (salePoint.phone2.isNotBlank()) {
                                        AppTextSemiBoldSmall {
                                            text = salePoint.phone2
                                        }
                                    }
                                }
                            }

                            Button {
                                size = Size.medium
                                variant = ButtonVariant.outlined
                                startIcon = RouteIcon.create()
                                sx {
                                    textTransform = None.none
                                }
                                onClick = { event ->
                                    if (salePoint.mapLink.orEmpty().isNotBlank()) {
                                        navigator.openUrl(salePoint.mapLink!!)
                                    }
                                    event.preventDefault()
                                }
                                +locale.mapRoute
                            }
                        }
                    }
                }
            }
        }
    }
}
