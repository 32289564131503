package uz.ferro.shop.navigator

enum class NavigationPage {
    LOGIN,
    ALL_PRODUCTS,
    ABOUT_COMPANY,
    STORES,
    ADMIN_HOME,
    ADMIN_BANNERS,
    ADMIN_CATEGORIES,
    ADMIN_PRODUCTS,
    ADMIN_PRODUCT_GROUPS,
    ADMIN_DEALERS,
    ADMIN_BRANDS,
    ADMIN_USERS,
    ADMIN_ORDER,
    ADMIN_SETTINGS,
    ADMIN_SALE_PERSON,
    ADMIN_HELP,
    ADMIN_PRICE_GENERATOR
}