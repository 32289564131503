package uz.ferro.shop.model

import kotlinx.serialization.Serializable

@Serializable
data class BannerComponentMeta(
    val rows: List<LayoutRow>
) {

    companion object {
        val default = BannerComponentMeta(
            rows = listOf(
                LayoutRow(
                    items = listOf(
                        LayoutItem(
                            widthRatio = 300,
                            heightRatio =200
                        ),
                        LayoutItem(
                            widthRatio = 300,
                            heightRatio = 200
                        )
                    )
                )
            )
        )
    }
}

