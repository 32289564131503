package uz.ferro.shop.ui.banner

import kotlinx.serialization.decodeFromString
import mui.material.Box
import mui.material.Stack
import mui.material.StackDirection
import mui.system.Breakpoint
import mui.system.responsive
import mui.system.sx
import react.FC
import react.Props
import react.useContext
import uz.ferro.shop.NavigationContext
import uz.ferro.shop.api.jsonSerializer
import uz.ferro.shop.model.BannerComponentMeta
import uz.ferro.shop.model.Component
import uz.ferro.shop.ui.ImageView
import web.cssom.Auto
import web.cssom.Cursor
import web.cssom.number
import web.cssom.pct
import web.cssom.px

external interface BannerGroupProps : Props {
    var component: Component
    var fitFirst: Boolean
    var isEditMode: Boolean
}

val BannerGroup = FC<BannerGroupProps> { props ->
    val navigator = useContext(NavigationContext)
    val component = props.component

    val banners = component.banners.orEmpty().sortedBy { it.sort }

    console.log(component.meta)
    val meta: BannerComponentMeta = component.meta?.let {
        jsonSerializer.decodeFromString(it)
    } ?: BannerComponentMeta.default

    val bannersIterator = banners.iterator()

    Stack {
        direction = responsive(StackDirection.column)
        spacing = responsive(
            Breakpoint.xs to 8.px,
            Breakpoint.sm to 8.px,
            Breakpoint.md to 12.px,
            Breakpoint.lg to 16.px
        )

        meta.rows.forEach { row ->
            Stack {
                direction = responsive(StackDirection.row)
                spacing = responsive(
                    Breakpoint.xs to 8.px,
                    Breakpoint.sm to 8.px,
                    Breakpoint.md to 12.px,
                    Breakpoint.lg to 16.px
                )

                row.items.forEach { layoutItem ->
                    val ratio: Double = layoutItem.widthRatio.toDouble() / layoutItem.heightRatio.coerceAtLeast(1)
                    Box {
                        sx {
                            aspectRatio = number(ratio)
                            flexGrow = number(ratio)
                            width = 0.pct
                            height = Auto.auto
                            cursor = Cursor.pointer
                        }

                        if (bannersIterator.hasNext()) {
                            val componentBanner = bannersIterator.next()
                            val banner = componentBanner.banner
                            ImageView {
                                sx {
                                    width = 100.pct
                                    height = 100.pct
                                    borderRadius = 5.px
                                }

                                url = banner.getPhotoUrl()
                                isCovered = true
                            }
                            onClick = {
                                if (props.isEditMode) {
                                    navigator.openAdminEditComponentBanner(
                                        componentId = componentBanner.component.id!!,
                                        componentBannerId = componentBanner.id!!
                                    )
                                } else {
                                    navigator.openUrl(banner.url)
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
