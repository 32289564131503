package uz.ferro.shop.pages.salepoints

import kotlinx.coroutines.MainScope
import kotlinx.coroutines.launch
import mui.material.Box
import mui.material.Grid
import mui.system.Breakpoint
import mui.system.responsive
import mui.system.sx
import react.FC
import react.Props
import react.useContext
import react.useEffectOnce
import react.useState
import uz.ferro.shop.AppContext
import uz.ferro.shop.LocaleContext
import uz.ferro.shop.model.SalePoint
import uz.ferro.shop.pages.MAX_PAGE_AREA
import uz.ferro.shop.pages.MarketPage
import uz.ferro.shop.pages.admin.salepoint.SalePointManager
import uz.ferro.shop.ui.AppTextBoldGiant
import uz.ferro.shop.util.md
import uz.ferro.shop.util.sm
import uz.ferro.shop.util.xs
import web.cssom.Auto
import web.cssom.pct
import web.cssom.px

val StoresPage = FC<Props> {
    val appContext = useContext(AppContext)
    val isMobile = appContext.isMd.not()
    val locale = useContext(LocaleContext)

    var salePoints by useState<List<SalePoint>>(emptyList())

    useEffectOnce {
        MainScope().launch {
            salePoints = SalePointManager.getSalePoints()
        }
    }

    MarketPage(
        hideCategory = true,
        hideMobileCatalog = true,
        fillWidthMode = true,
        fullBlock = {
            Box {
                sx {
                    width = 100.pct
                }
                Box {
                    sx {
                        width = 100.pct
                        maxWidth = MAX_PAGE_AREA
                        paddingLeft = 16.px
                        paddingRight = 16.px
                        marginLeft = Auto.auto
                        marginRight = Auto.auto
                    }

                    AppTextBoldGiant {
                        sx {
                            marginTop = 8.px
                            fontSize = responsive(
                                Breakpoint.xs to 25.px,
                                Breakpoint.md to 31.px
                            )
                        }
                        text = locale.dealers
                    }

                    Grid {
                        container = true
                        spacing = responsive(16.px)
                        sx {
                            marginTop = 16.px
                        }

                        salePoints.forEach { salePoint ->
                            Grid {
                                item = true
                                xs = 6
                                sm = 6
                                md = 3

                                SalePointView {
                                    this.salePoint = salePoint
                                }
                            }
                        }
                    }
                }
            }
        }
    ).invoke()
}